import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("品质调整单号"),
    dataIndex: "number",
    sorter: true,
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: T("状态"),
    dataIndex: "status",
    width: 100,
    ellipsis: true,
    customRender: (value, item, index) => (item.is_void ? T("已作废") : T("已完成")),
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
    width: 160,
    ellipsis: true,
  },
  {
    title: T("创建人"),
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("创建时间"),
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    fixed: "right",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];
